import React from 'react';
import useSWR from 'swr';
import { formatDistanceToNow, parseISO, format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Countries } from './Countries';

const urlFullAggregated = 'https://covid19.mathdro.id/api';

interface CoronaData {
  confirmed: Aggregated;
  recovered: Aggregated;
  deaths: Aggregated;
  dailySummary: string; // url
  dailyTimeSeries: ApiDetails;
  image: string; // url
  source: string; // url
  countries: string; // url
  countryDetail: ApiDetails;
  lastUpdate: string; // date
}

export interface Aggregated {
  value: number;
  detail: string; // url
}

interface ApiDetails {
  pattern: string;
  example: string; // url
}

export const DataFetcher: React.FC = () => {
  const { data: coronaData, error: coronaDataError } = useSWR<CoronaData>(
    urlFullAggregated
  );

  if (coronaData === undefined) {
    return <div>Lade...</div>;
  }

  if (coronaDataError !== undefined) {
    return <div>Fehler beim Laden der Daten.</div>;
  }

  const lastUpdateParsed = parseISO(coronaData.lastUpdate);

  return (
    <>
      <div className="section-worldwide">
        <div className="subheadline-wrapper">
          <span className="subheadline">Weltweit</span>
        </div>
        <div className="numbers">
          <span className="cases-label">Bestätigte Fälle:</span>
          <span className="accumulated-cases">
            {coronaData.confirmed.value.toLocaleString()}
          </span>
        </div>
        <div className="numbers">
          <span className="cases-label">Wieder gesund:</span>
          <span className="recovered-cases">
            {coronaData.recovered.value.toLocaleString()}
          </span>
        </div>
        <div className="numbers">
          <span className="cases-label">Todesfälle:</span>
          <span className="death-cases">
            {coronaData.deaths.value.toLocaleString()}
          </span>
        </div>
        <div className="last-update">
          <strong>Stand: </strong>
          <div>
            <span>
              {format(lastUpdateParsed, 'dd.MM.yyyy, HH:mm', {
                locale: de
              })}{' '}
              Uhr{' '}
            </span>
            <span className="date-human-readable">
              ({formatDistanceToNow(lastUpdateParsed, { locale: de })})
            </span>
          </div>
        </div>
      </div>
      <Countries urlAvailableCountries={coronaData.countries} />
      <div className="footer">&copy; Mathias Klassen</div>
    </>
  );
};
