import React from 'react';
import useSWR from 'swr';
import { Aggregated } from './DataFetcher';
import { parseISO, format, formatDistanceToNow } from 'date-fns';
import { de } from 'date-fns/locale';

export interface CountryDetail {
  confirmed: Aggregated;
  recovered: Aggregated;
  deaths: Aggregated;
  lastUpdate: string; // date
}

export const Country: React.FC<{
  countryUrl: string;
  selectedCountryIso3: string;
}> = props => {
  const { data: country, error: countryError } = useSWR<CountryDetail>(
    `${props.countryUrl}/${props.selectedCountryIso3}`
  );

  if (country === undefined) {
    return <div className="top-margin">Lade...</div>;
  }

  if (countryError !== undefined) {
    return <div className="top-margin">Fehler beim Laden der Daten.</div>;
  }

  const lastUpdateParsed = parseISO(country.lastUpdate);

  return (
    <div className="top-margin">
      <div className="numbers">
        <span className="cases-label">Bestätigte Fälle:</span>
        <span className="accumulated-cases">
          {country.confirmed.value.toLocaleString()}
        </span>
      </div>
      <div className="numbers">
        <span className="cases-label">Wieder gesund:</span>
        <span className="recovered-cases">
          {country.recovered.value.toLocaleString()}
        </span>
      </div>
      <div className="numbers">
        <span className="cases-label">Todesfälle:</span>
        <span className="death-cases">
          {country.deaths.value.toLocaleString()}
        </span>
      </div>
      <div className="last-update">
        <strong>Stand: </strong>
        <div>
          <span>
            {format(lastUpdateParsed, 'dd.MM.yyyy, HH:mm', {
              locale: de
            })}{' '}
            Uhr{' '}
          </span>
          <span className="date-human-readable">
            ({formatDistanceToNow(lastUpdateParsed, { locale: de })})
          </span>
        </div>
      </div>
    </div>
  );
};
