import React from 'react';
import { SWRConfig } from 'swr';
import './App.css';
import { DataFetcher } from './DataFetcher';
import { format } from 'date-fns';

const fetcher = (...args: any) =>
  (window as any).fetch(...args).then((res: { json: () => any }) => res.json());

const App: React.FC = () => {
  return (
    <SWRConfig
      value={{
        fetcher
      }}
    >
      <header className="header">
        <div className="headline-current-date">
          {format(new Date(), 'dd.MM.yyyy')}
        </div>
        <div className="headline">Corona Statistik</div>
      </header>
      <DataFetcher />
    </SWRConfig>
  );
};

export default App;
