import React, { useState } from 'react';
import useSWR from 'swr';
import { Country } from './Country';

interface Countries {
  countries: Country[];
}

interface Country {
  name: string;
  iso2?: string;
  iso3?: string;
}

export const Countries: React.FC<{
  urlAvailableCountries: string;
}> = props => {
  const { data: countries, error: countriesError } = useSWR<Countries>(
    props.urlAvailableCountries
  );
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  if (countries === undefined) {
    return <div className="top-margin">Lade...</div>;
  }

  if (countriesError !== undefined) {
    return <div className="top-margin">Fehler beim Laden der Daten.</div>;
  }

  const defaultEmptyDropdownValue: Country = { name: '---', iso3: '---' };

  const availableCountries = [defaultEmptyDropdownValue].concat(
    countries.countries.filter(country => !!country.iso3)
  );

  const availableOptions = availableCountries.map(country => (
    <option key={country.iso3} value={country.iso3}>
      {country.name}
    </option>
  ));

  return (
    <div className="section-land">
      <div className="subheadline-wrapper">
        <span className="subheadline">Land</span>
      </div>
      <div className="select-land-wrapper">
        <span className="select-land-text">Land wählen: </span>
        <div className="select-land-dropdown-wrapper">
          <select
            className="select-land-dropdown"
            onChange={e => {
              const foundCountry = availableCountries.find(
                country =>
                  country.iso3 !== defaultEmptyDropdownValue.iso3 &&
                  country.iso3 === e.target.value
              );
              if (!foundCountry) {
                return;
              }
              setSelectedCountry(foundCountry);
            }}
            value={selectedCountry?.iso3}
          >
            {availableOptions}
          </select>
        </div>
      </div>
      {!!selectedCountry && (
        <Country
          countryUrl={props.urlAvailableCountries}
          selectedCountryIso3={selectedCountry.iso3!}
        />
      )}
    </div>
  );
};
